import React from "react";
import styles from "./styles.module.css";
import Skeleton from "react-loading-skeleton";

const Statics = ({ statics, loading }) => {
  
    return (
      <section className={styles.section}>
        <h3 className="section__title">Statics</h3>
        <div>
          {/* box */}
          <div className={styles.list}>
            <div className={styles.box}>
              <span className={styles.box__price}>
                {loading ? (
                  <Skeleton width={100} height={30} />
                ) : (
                  statics?.averagePrice.toFixed(2) + " SR"
                )}
              </span>
              <span className={styles.subtitle}>Average Price</span>
              {/* <span className={styles.blue__subtitle}>Based on 1000 search</span> */}
            </div>
            <div className={styles.box}>
              <span className={styles.box__price}>
                {loading ? (
                  <Skeleton width={100} height={30} />
                ) : (
                  statics?.lowestPrice.toFixed(2) + " SR"
                )}
              </span>
              <span className={styles.subtitle}>Lowest Price</span>
              {/* <span className={styles.blue__subtitle}>From 2 Suppliers</span> */}
            </div>
            <div className={styles.box}>
              <span className={styles.box__price}>
                {loading ? (
                  <Skeleton width={100} height={30} />
                ) : (
                  statics?.highestPrice.toFixed(2) + " SR"
                )}
              </span>
              <span className={styles.subtitle}>highest Price</span>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Statics;
