import { useState } from "react";
import { toast } from "react-toastify";
// import { amazonRes, jarirRes, officeRes } from "../data";
import api from "../api";

export const useApi = (endpoint, method) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null)

  const onRequest = async ({ platform, text }) => {
    setLoading(true);
    try {
      const amazonRes = (platform === "amazon" || platform === "all") && 
      (await api[method](endpoint, {
        url: "https://www.amazon.sa/",
        category: text,
        urls: ["https://www.amazon.sa/"],
      }));
      const jarirRes =
        (platform === "jarir" || platform === "all") &&
        (await api[method](`${endpoint}/jarir`, {
          url: "https://www.jarir.com/sa-en",
          category: text,
          urls: ["https://www.jarir.com/sa-en"],
        }));
      const officeRes =
        (platform === "office" || platform === "all") &&
        (await api[method](`${endpoint}/office-supplier`, {
          url: "https://officesupply.sa/en",
          category: text,
          urls: ["https://officesupply.sa/en"],
        }));
      setLoading(true);

      // set Data for platforms
      platform === "amazon" && setData(amazonRes);
      platform === "jarir" && setData(jarirRes);
      platform === "office" && setData(officeRes);
      platform === "all" &&
        setData({
          products: [
            ...jarirRes?.products,
            ...amazonRes?.products,
            ...officeRes?.products,
          ],
          highestPrice: Math.max(
            ...[
              amazonRes?.highestPrice,
              jarirRes.highestPrice,
              officeRes?.highestPrice,
            ]
          ),
          averagePrice:
            (amazonRes?.averagePrice +
            jarirRes.averagePrice +
            officeRes?.averagePrice) / 3,
          lowestPrice: Math.min(
            ...[
              amazonRes?.lowestPrice,
              jarirRes.lowestPrice,
              officeRes?.lowestPrice,
            ]
          ),
          testResponse: "tested ok",
        });
        setLoading(false);
      // return res;
    } catch (err) {
      console.log(err);
      if (typeof err?.response?.data?.message === "string") {
        toast.error(err?.response?.data?.message || "something went wrong!");
      } else {
        err?.response?.data?.message?.map((msg) =>
          toast.error(msg || "something went wrong!")
        );
      }
      setError(err?.response?.data?.message || "something went wrong!");
      setLoading(false);
      return error;
    }
    setLoading(false)
  };

  return { loading, error, data, onRequest };
};
