import React from "react";
import styles from "./styles.module.css";
import { ActiveStar, FullAmazonLogo, UnActiveStar } from "../../icons";
import JarirImage from '../../assets/jarir.svg';
import OfficeImage from '../../assets/officesupply.webp';
import Skeleton from "react-loading-skeleton";

const CardsList = ({ data, loading }) => {

  if(loading) return (
    <div className={styles.list}>
      {Array(5)
        .fill("")
        .map((_, i) => (
          <div className={styles.box} key={i}>
            <div>
              <Skeleton width={"100%"} height={"230px"} />
            </div>
            <div className={styles.details}>
              <div className={styles.price}>
                <Skeleton width={"90px"} height={"22px"} />
              </div>
              <div className={styles.rating}>
                <div className={styles.stars__list}>
                  <Skeleton width={"100px"} height={"18px"} />
                </div>
              </div>
              <Skeleton count={4} />
              {/* <h3 className={styles.title}>{product?.title}</h3> */}
            </div>
            {/* {product?.link && (
              <a
                href={product?.link}
                target="_blank"
                className={styles.platform__btn}
                rel="noreferrer"
              >
                {product?.link && product?.link.includes("amazon") ? (
                  <FullAmazonLogo />
                ) : product?.link && product?.link?.includes("office") ? (
                  <img
                    className={styles.platform__img}
                    src={OfficeImage}
                    alt=""
                  />
                ) : (
                  <img
                    className={styles.platform__img}
                    src={JarirImage}
                    alt=""
                  />
                )}
              </a>
            )} */}
            <div className={styles.platform__btn}>
              <Skeleton width={"110px"} height={"36px"} />
            </div>
          </div>
        ))}
    </div>
  );
  return (
    <div className={styles.list}>
      {data?.map((product, i) => (
        <div className={styles.box} key={i}>
            <img
              src={
                product?.productImage ||
                "https://techryn.com/wp-content/uploads/2020/09/iPhone-13-leaks.jpg"
              }
              alt="product__image"
            />
          <div className={styles.details}>
            <div className={styles.price}>
              <span>
                  {product?.price}
              </span>
            </div>
            <div className={styles.rating}>
                <div className={styles.stars__list}>
                  {Array(5)
                    .fill("")
                    ?.map((_, i) => {
                      if (+i + 1 <= +Math.floor(+product?.rating)) {
                        return <ActiveStar key={i} />;
                      } else {
                        return <UnActiveStar key={i} />;
                      }
                    })}
                </div>
            </div>
            <h3 className={styles.title}>{product?.title}</h3>
          </div>
          {product?.link && (
            <a
              href={product?.link}
              target="_blank"
              className={styles.platform__btn}
              rel="noreferrer"
            >
              {product?.link && product?.link.includes("amazon") ? (
                <FullAmazonLogo />
              ) : product?.link && product?.link?.includes("office") ? (
                <img
                  className={styles.platform__img}
                  src={OfficeImage}
                  alt=""
                />
              ) : (
                <img className={styles.platform__img} src={JarirImage} alt="" />
              )}
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

export default CardsList;
