import { ActiveStar, AmazonIcon, UnActiveStar } from "../../icons";
import styles from "./styles.module.css";
import JarirImage from "../../assets/jarir.svg";
import OfficeImage from "../../assets/officesupply.webp";
import Skeleton from "react-loading-skeleton";
// import { CiStar } from "react-icons/ci";
// import { FaRegStar, FaStar } from "react-icons/fa";

const Table = ({ data, loading }) => {
  return (
    <table className={styles.main__table}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Product name</th>
          <th>supplier</th>
          <th>Price</th>
          {/* <th>Stock Status</th> */}
          {/* <th>Pcs available</th> */}
          <th>Rate</th>
        </tr>
      </thead>
      <tbody>
        {loading ? Array(6).fill('').map((_, i) => (
          <tr>
            <td>
              <Skeleton width={"100%"} height="36px" />
            </td>
            <td>
              <Skeleton width={"100%"} height="36px" />
            </td>
            <td>
              <Skeleton width={"100%"} height="36px" />
            </td>
            <td>
              <Skeleton width={"100%"} height="36px" />
            </td>
            <td>
              <Skeleton width={"100%"} height="36px" />
            </td>
          </tr>
        )) : data?.map((product, i) => (
          <tr
            key={i}
            onClick={() => window.open(product?.link, "_blank")}
            style={{ cursor: "pointer" }}
          >
            <td>{i + 1}</td>
            <td>
              <div className={styles.product__image}>
                <span
                  className={styles.title}
                  style={{ flex: 1, textAlign: "center" }}
                >
                  {product?.title}
                </span>
                <img
                  src={
                    product?.productImage ||
                    "https://static.vecteezy.com/system/resources/previews/000/429/440/original/vector-coming-soon-message-illuminated-with-stage-light.jpg"
                  }
                  alt="product__image"
                />
              </div>
            </td>
            <td>
              <span className={styles.supplier}>
                {product?.link && product?.link.includes("amazon") ? (
                  <AmazonIcon />
                ) : product?.link && product?.link?.includes("office") ? (
                  <img
                    className={styles.platform__img}
                    src={OfficeImage}
                    alt=""
                  />
                ) : (
                  <img
                    className={styles.platform__img}
                    src={JarirImage}
                    alt=""
                  />
                )}
                {/* Amazon */}
              </span>
            </td>
            <td>{product?.price}</td>
            {/* <td className={styles.stock__status}>Available</td> */}
            {/* <td>250</td> */}
            <td>
              <div className={styles.rating}>
                <div className={styles.list}>
                  {Array(5)
                    .fill("")
                    ?.map((_, i) => {
                      if (+i + 1 <= +Math.floor(+product?.rating)) {
                        return <ActiveStar key={i} />;
                      } else {
                        return <UnActiveStar key={i} />;
                      }
                    })}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
