export const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clipPath="url(#clip0_9_1738)">
      <path
        d="M21 12C21 13.8789 20.4428 15.7156 19.399 17.2779C18.3551 18.8402 16.8714 20.0578 15.1355 20.7769C13.3996 21.4959 11.4895 21.684 9.64665 21.3175C7.80383 20.9509 6.11109 20.0461 4.78249 18.7175C3.45389 17.3889 2.5491 15.6962 2.18254 13.8534C1.81598 12.0105 2.00412 10.1004 2.72315 8.36451C3.44218 6.62861 4.65982 5.14491 6.22209 4.10104C7.78435 3.05717 9.62108 2.5 11.5 2.5C14.0196 2.5 16.4359 3.50089 18.2175 5.28249C19.9991 7.06408 21 9.48044 21 12Z"
        stroke="#9A9A9A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 22.5L20 20.5"
        stroke="#9A9A9A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9_1738">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const AmazonIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.3454 26.0746C14.605 31.662 7.31879 26.9872 2.65465 24.1478C2.36603 23.9689 1.87549 24.1897 2.3011 24.6785C3.85496 26.5626 8.94729 31.1037 15.5943 31.1037C22.2458 31.1037 26.2029 27.4744 26.6979 26.8413C27.1895 26.2135 26.8423 25.8672 26.3454 26.0746ZM29.6427 24.2537C29.3274 23.8432 27.7256 23.7666 26.7175 23.8905C25.7079 24.0107 24.1924 24.6278 24.3241 24.9983C24.3917 25.1371 24.5297 25.0748 25.2233 25.0125C25.9188 24.9431 27.8672 24.6972 28.2731 25.2279C28.681 25.7623 27.6517 28.3079 27.4638 28.7184C27.2821 29.129 27.5331 29.2349 27.8743 28.9614C28.2108 28.6881 28.8199 27.9802 29.2287 26.9785C29.6347 25.9713 29.8823 24.5663 29.6427 24.2537Z"
      fill="#FF9900"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4513 14.6737C18.4513 16.1397 18.4883 17.3623 17.7473 18.6642C17.1492 19.7228 16.2018 20.3738 15.1433 20.3738C13.6983 20.3738 12.8568 19.2729 12.8568 17.6481C12.8568 14.4407 15.7307 13.8586 18.4513 13.8586V14.6737ZM22.2461 23.8458C21.9973 24.0681 21.6374 24.084 21.357 23.9358C20.1079 22.8985 19.8856 22.4168 19.1975 21.4271C17.1334 23.5336 15.6726 24.1634 12.9945 24.1634C9.82942 24.1634 7.36297 22.2103 7.36297 18.2991C7.36297 15.2453 9.01969 13.1652 11.3748 12.1491C13.4179 11.2493 16.2707 11.0905 18.4513 10.8418V10.3549C18.4513 9.46035 18.52 8.40185 17.9961 7.62917C17.5356 6.93581 16.657 6.64999 15.8842 6.64999C14.4499 6.64999 13.1691 7.38563 12.8568 8.90991C12.7932 9.24872 12.5445 9.58218 12.2058 9.59802L8.5538 9.20644C8.24689 9.13749 7.90818 8.88882 7.99286 8.41769C8.83438 3.99314 12.8303 2.6593 16.4081 2.6593C18.2394 2.6593 20.6317 3.14627 22.0767 4.53299C23.908 6.24246 23.7333 8.52357 23.7333 11.0058V16.8701C23.7333 18.6325 24.4637 19.4052 25.1517 20.358C25.3951 20.6967 25.4481 21.1043 25.1411 21.3582C24.3737 21.9986 23.0081 23.1895 22.2566 23.8564L22.2461 23.8458Z"
      fill="#000008"
    />
    <path
      d="M26.3454 26.0746C14.605 31.662 7.31879 26.9872 2.65465 24.1478C2.36603 23.9689 1.87549 24.1897 2.3011 24.6785C3.85496 26.5626 8.94729 31.1037 15.5943 31.1037C22.2458 31.1037 26.2029 27.4744 26.6979 26.8413C27.1895 26.2135 26.8423 25.8672 26.3454 26.0746ZM29.6427 24.2537C29.3274 23.8432 27.7256 23.7666 26.7175 23.8905C25.7079 24.0107 24.1924 24.6278 24.3241 24.9983C24.3917 25.1371 24.5297 25.0748 25.2233 25.0125C25.9188 24.9431 27.8672 24.6972 28.2731 25.2279C28.681 25.7623 27.6517 28.3079 27.4638 28.7184C27.2821 29.129 27.5331 29.2349 27.8743 28.9614C28.2108 28.6881 28.8199 27.9802 29.2287 26.9785C29.6347 25.9713 29.8823 24.5663 29.6427 24.2537Z"
      fill="#FF9900"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4513 14.6737C18.4513 16.1397 18.4883 17.3623 17.7473 18.6642C17.1492 19.7228 16.2018 20.3738 15.1433 20.3738C13.6983 20.3738 12.8568 19.2729 12.8568 17.6481C12.8568 14.4407 15.7307 13.8586 18.4513 13.8586V14.6737ZM22.2461 23.8458C21.9973 24.0681 21.6374 24.084 21.357 23.9358C20.1079 22.8985 19.8856 22.4168 19.1975 21.4271C17.1334 23.5336 15.6726 24.1634 12.9945 24.1634C9.82942 24.1634 7.36297 22.2103 7.36297 18.2991C7.36297 15.2453 9.01969 13.1652 11.3748 12.1491C13.4179 11.2493 16.2707 11.0905 18.4513 10.8418V10.3549C18.4513 9.46035 18.52 8.40185 17.9961 7.62917C17.5356 6.93581 16.657 6.64999 15.8842 6.64999C14.4499 6.64999 13.1691 7.38563 12.8568 8.90991C12.7932 9.24872 12.5445 9.58218 12.2058 9.59802L8.5538 9.20644C8.24689 9.13749 7.90818 8.88882 7.99286 8.41769C8.83438 3.99314 12.8303 2.6593 16.4081 2.6593C18.2394 2.6593 20.6317 3.14627 22.0767 4.53299C23.908 6.24246 23.7333 8.52357 23.7333 11.0058V16.8701C23.7333 18.6325 24.4637 19.4052 25.1517 20.358C25.3951 20.6967 25.4481 21.1043 25.1411 21.3582C24.3737 21.9986 23.0081 23.1895 22.2566 23.8564L22.2461 23.8458Z"
      fill="#000008"
    />
  </svg>
);

export const ActiveStar = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.25 12.5821L13.194 15.5269L11.882 9.97686L16.25 6.24264L10.498 5.76107L8.25 0.526855L6.002 5.76107L0.25 6.24264L4.618 9.97686L3.306 15.5269L8.25 12.5821Z"
      fill="#FF9017"
    />
  </svg>
);

export const UnActiveStar = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.25 12.5821L13.194 15.5269L11.882 9.97686L16.25 6.24264L10.498 5.76107L8.25 0.526855L6.002 5.76107L0.25 6.24264L4.618 9.97686L3.306 15.5269L8.25 12.5821Z"
      fill="#D5CDC5"
    />
  </svg>
); 

export const FullAmazonLogo = () => (
  <svg
    width="110"
    height="36"
    viewBox="0 0 110 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.0605 27.3318C59.8567 30.4019 53.7636 32.0399 48.5175 32.0399C41.1616 32.0399 34.5395 29.3442 29.5296 24.8608C29.1359 24.5082 29.4886 24.0277 29.961 24.3023C35.3676 27.4192 42.0528 29.2943 48.9583 29.2943C53.6156 29.2943 58.7388 28.3396 63.4496 26.3584C64.1613 26.0588 64.7564 26.8201 64.0605 27.3318Z"
      fill="#FF9900"
    />
    <path
      d="M65.8082 25.3505C65.2729 24.6703 62.2562 25.0291 60.9022 25.1883C60.4897 25.2382 60.4267 24.8825 60.7983 24.6267C63.2009 22.9512 67.1433 23.4348 67.6031 23.9964C68.0628 24.5611 67.4834 28.4768 65.2256 30.3457C64.8793 30.6327 64.5486 30.4798 64.7029 30.0992C65.2099 28.8449 66.3466 26.0338 65.8082 25.3505Z"
      fill="#FF9900"
    />
    <path
      d="M60.9965 12.7986V11.17C60.9965 10.9235 61.1855 10.7582 61.4122 10.7582H68.7712C69.0074 10.7582 69.1963 10.9266 69.1963 11.17V12.5646C69.1932 12.7986 68.9948 13.1044 68.6421 13.588L64.8288 18.9825C66.2458 18.9482 67.7415 19.1573 69.0263 19.8749C69.316 20.0371 69.3947 20.2742 69.4167 20.5082V22.2461C69.4167 22.4832 69.1522 22.7609 68.8751 22.6174C66.611 21.4411 63.6038 21.3132 61.1005 22.6299C60.8454 22.7671 60.5777 22.4926 60.5777 22.2555V20.605C60.5777 20.3398 60.5809 19.8874 60.8485 19.4849L65.2665 13.2074H61.4216C61.1855 13.2074 60.9965 13.042 60.9965 12.7986Z"
      fill="#221F1F"
    />
    <path
      d="M34.1517 22.9607H31.9129C31.6987 22.9451 31.5287 22.7859 31.5129 22.5831V11.1982C31.5129 10.9704 31.705 10.7894 31.9443 10.7894H34.0321C34.2494 10.7988 34.4225 10.9642 34.4383 11.1701V12.6583H34.4792C35.024 11.22 36.0474 10.5492 37.4266 10.5492C38.8279 10.5492 39.7033 11.22 40.333 12.6583C40.8747 11.22 42.1059 10.5492 43.4253 10.5492C44.3637 10.5492 45.3902 10.933 46.0168 11.7941C46.7253 12.7519 46.5805 14.1435 46.5805 15.3634L46.5773 22.5488C46.5773 22.7766 46.3853 22.9607 46.1459 22.9607H43.9102C43.6866 22.9451 43.5071 22.7672 43.5071 22.5488V16.5147C43.5071 16.0342 43.5512 14.8361 43.4442 14.3806C43.2773 13.6162 42.7766 13.4009 42.1279 13.4009C41.5863 13.4009 41.0195 13.7597 40.7896 14.3338C40.5598 14.9079 40.5818 15.8688 40.5818 16.5147V22.5488C40.5818 22.7766 40.3897 22.9607 40.1504 22.9607H37.9147C37.688 22.9451 37.5116 22.7672 37.5116 22.5488L37.5085 16.5147C37.5085 15.2448 37.7194 13.3759 36.1293 13.3759C34.5202 13.3759 34.5831 15.198 34.5831 16.5147V22.5488C34.5831 22.7766 34.3911 22.9607 34.1517 22.9607Z"
      fill="#221F1F"
    />
    <path
      d="M75.5317 10.5492C78.8538 10.5492 80.6518 13.3759 80.6518 16.9702C80.6518 20.4428 78.6648 23.1978 75.5317 23.1978C72.2694 23.1978 70.4934 20.371 70.4934 16.8485C70.4934 13.3042 72.2914 10.5492 75.5317 10.5492ZM75.5506 12.8736C73.9005 12.8736 73.7966 15.1013 73.7966 16.4897C73.7966 17.8813 73.7746 20.8515 75.5317 20.8515C77.2667 20.8515 77.3486 18.4553 77.3486 16.9952C77.3486 16.0342 77.3076 14.886 77.0148 13.975C76.7629 13.1825 76.2622 12.8736 75.5506 12.8736Z"
      fill="#221F1F"
    />
    <path
      d="M84.9594 22.9607H82.73C82.5064 22.9451 82.3269 22.7672 82.3269 22.5488L82.3238 11.1607C82.3427 10.9517 82.5285 10.7894 82.7552 10.7894H84.8303C85.0255 10.7988 85.1861 10.9298 85.2302 11.1077V12.8486H85.2712C85.8978 11.2918 86.7763 10.5492 88.3225 10.5492C89.327 10.5492 90.3063 10.908 90.9361 11.8908C91.5218 12.8018 91.5218 14.3338 91.5218 15.4352V22.6019C91.4966 22.8015 91.3108 22.9607 91.0903 22.9607H88.8452C88.6405 22.9451 88.4705 22.7953 88.4484 22.6019V16.418C88.4484 15.1731 88.5933 13.351 87.0471 13.351C86.5024 13.351 86.0017 13.7129 85.7529 14.262C85.438 14.9578 85.3971 15.6504 85.3971 16.418V22.5488C85.394 22.7766 85.1987 22.9607 84.9594 22.9607Z"
      fill="#221F1F"
    />
    <path
      d="M55.1299 17.5224V17.0419C53.5114 17.0419 51.8015 17.3851 51.8015 19.2759C51.8015 20.2337 52.3022 20.8827 53.1618 20.8827C53.7916 20.8827 54.3553 20.4989 54.7111 19.8749C55.1519 19.1074 55.1299 18.3867 55.1299 17.5224ZM57.3877 22.9294C57.2397 23.0605 57.0255 23.0698 56.8587 22.9825C56.1155 22.3709 55.9833 22.087 55.5739 21.5036C54.3458 22.7453 53.4767 23.1166 51.8834 23.1166C50.0003 23.1166 48.5329 21.9653 48.5329 19.6596C48.5329 17.8594 49.5185 16.6332 50.9198 16.0342C52.1353 15.5037 53.8326 15.4101 55.1299 15.2635V14.9765C55.1299 14.4492 55.1708 13.8252 54.8591 13.3697C54.5851 12.9609 54.0624 12.7924 53.6027 12.7924C52.7493 12.7924 51.9873 13.2261 51.8015 14.1247C51.7637 14.3244 51.6157 14.5209 51.4142 14.5303L49.2414 14.2994C49.0588 14.2589 48.8573 14.1122 48.9077 13.8345C49.4083 11.2262 51.7858 10.4399 53.9144 10.4399C55.0039 10.4399 56.4272 10.727 57.2869 11.5444C58.3764 12.5522 58.2725 13.8969 58.2725 15.3602V18.8172C58.2725 19.8562 58.7071 20.3117 59.1164 20.8733C59.2613 21.073 59.2928 21.3132 59.1101 21.463C58.6535 21.8405 57.8411 22.5425 57.394 22.9357L57.3877 22.9294Z"
      fill="#221F1F"
    />
    <path
      d="M25.7789 17.5224V17.0419C24.1604 17.0419 22.4505 17.3851 22.4505 19.2759C22.4505 20.2337 22.9512 20.8827 23.8108 20.8827C24.4406 20.8827 25.0043 20.4989 25.3601 19.8749C25.801 19.1074 25.7789 18.3867 25.7789 17.5224ZM28.0367 22.9294C27.8887 23.0605 27.6746 23.0698 27.5077 22.9825C26.7645 22.3709 26.6323 22.087 26.2229 21.5036C24.9948 22.7453 24.1257 23.1166 22.5324 23.1166C20.6493 23.1166 19.1819 21.9653 19.1819 19.6596C19.1819 17.8594 20.1676 16.6332 21.5688 16.0342C22.7843 15.5037 24.4816 15.4101 25.7789 15.2635V14.9765C25.7789 14.4492 25.8198 13.8252 25.5081 13.3697C25.2342 12.9609 24.7114 12.7924 24.2517 12.7924C23.3983 12.7924 22.6363 13.2261 22.4505 14.1247C22.4127 14.3244 22.2647 14.5209 22.0632 14.5303L19.8905 14.2994C19.7078 14.2589 19.5063 14.1122 19.5567 13.8345C20.0573 11.2262 22.4348 10.4399 24.5634 10.4399C25.653 10.4399 27.0763 10.727 27.9359 11.5444C29.0254 12.5522 28.9215 13.8969 28.9215 15.3602V18.8172C28.9215 19.8562 29.3561 20.3117 29.7654 20.8733C29.9103 21.073 29.9418 21.3132 29.7591 21.463C29.3025 21.8405 28.4901 22.5425 28.043 22.9357L28.0367 22.9294Z"
      fill="#221F1F"
    />
  </svg>
);

export const FilterIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_85_117)">
      <path
        d="M17.5726 10.1384C16.3132 10.1395 15.1056 10.6403 14.215 11.5308C13.3245 12.4214 12.8237 13.629 12.8226 14.8884C12.8222 15.7531 13.061 16.6011 13.5126 17.3384C13.933 18.04 14.5279 18.6206 15.2394 19.0237C15.951 19.4269 16.7548 19.6388 17.5726 19.6388C18.3904 19.6388 19.1943 19.4269 19.9058 19.0237C20.6174 18.6206 21.2123 18.04 21.6326 17.3384C22.0855 16.6016 22.3244 15.7533 22.3226 14.8884C22.3229 14.2646 22.2002 13.6468 21.9616 13.0704C21.723 12.494 21.3731 11.9702 20.932 11.5291C20.4908 11.088 19.9671 10.7381 19.3907 10.4995C18.8143 10.2608 18.1965 10.1382 17.5726 10.1384ZM19.3526 15.6184H18.3226V16.6884C18.3226 16.8873 18.2436 17.0781 18.103 17.2188C17.9623 17.3594 17.7715 17.4384 17.5726 17.4384C17.3737 17.4384 17.183 17.3594 17.0423 17.2188C16.9016 17.0781 16.8226 16.8873 16.8226 16.6884V15.6184H15.8026C15.6037 15.6184 15.413 15.5394 15.2723 15.3988C15.1317 15.2581 15.0526 15.0673 15.0526 14.8684C15.0526 14.6695 15.1317 14.4788 15.2723 14.3381C15.413 14.1974 15.6037 14.1184 15.8026 14.1184H16.8226V13.1384C16.8226 12.9395 16.9016 12.7488 17.0423 12.6081C17.183 12.4674 17.3737 12.3884 17.5726 12.3884C17.7715 12.3884 17.9623 12.4674 18.103 12.6081C18.2436 12.7488 18.3226 12.9395 18.3226 13.1384V14.1184H19.3526C19.5515 14.1184 19.7423 14.1974 19.883 14.3381C20.0236 14.4788 20.1026 14.6695 20.1026 14.8684C20.1026 15.0673 20.0236 15.2581 19.883 15.3988C19.7423 15.5394 19.5515 15.6184 19.3526 15.6184Z"
        fill="#636363"
      />
      <path
        d="M21.2726 4.15843V6.37843C21.2147 7.24639 20.8618 8.06849 20.2726 8.70843L20.0926 8.86843C20.021 8.93045 19.9342 8.97238 19.8411 8.98995C19.748 9.00751 19.6519 9.00009 19.5626 8.96843C19.3626 8.89843 19.1626 8.84843 18.9626 8.79843C18.5075 8.68873 18.0407 8.635 17.5726 8.63843C15.9152 8.63896 14.3257 9.29761 13.1538 10.4696C11.9818 11.6416 11.3231 13.231 11.3226 14.8884C11.3199 16.0242 11.6314 17.1386 12.2226 18.1084C12.7075 18.9242 13.3793 19.6131 14.1826 20.1184C14.239 20.148 14.2879 20.19 14.3257 20.2412C14.3635 20.2924 14.3892 20.3516 14.4007 20.4142C14.4123 20.4768 14.4095 20.5413 14.3925 20.6026C14.3755 20.664 14.3447 20.7207 14.3026 20.7684C14.2326 20.8284 14.1626 20.8784 14.0926 20.9284L12.6926 21.8384C12.3858 22.0267 12.0344 22.1299 11.6745 22.1375C11.3147 22.1451 10.9593 22.0569 10.6447 21.8818C10.3302 21.7067 10.068 21.4512 9.88482 21.1413C9.70168 20.8314 9.60427 20.4784 9.60259 20.1184V14.7684C9.55162 13.9982 9.2732 13.2603 8.80259 12.6484L5.01259 8.60843C4.4953 8.05458 4.17844 7.34341 4.11259 6.58843V4.25843C4.10177 3.988 4.14489 3.71813 4.23944 3.46454C4.33399 3.21095 4.47808 2.97872 4.66331 2.78139C4.84854 2.58406 5.0712 2.42558 5.3183 2.31519C5.56541 2.2048 5.83202 2.14472 6.10259 2.13843H19.2826C19.8135 2.14501 20.3204 2.3609 20.693 2.73915C21.0657 3.11741 21.274 3.62745 21.2726 4.15843Z"
        fill="#636363"
      />
    </g>
    <defs>
      <clipPath id="clip0_85_117">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.692627 0.138428)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const DropdownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <g clip-path="url(#clip0_928_10198)">
      <path
        d="M15.4405 6.70654L10.5505 11.5965C10.2715 11.8739 9.894 12.0296 9.50055 12.0296C9.10709 12.0296 8.72962 11.8739 8.45055 11.5965L3.56055 6.70654"
        stroke="#00B16D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_928_10198">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.500977 -0.00585938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CheckedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
  >
    <g clipPath="url(#clip0_393_4829)">
      <path
        d="M1.79883 6.761L6.59499 11.5572L16.2043 1.96484"
        stroke="#6278FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_393_4829">
        <rect
          width="18"
          height="12.6615"
          fill="white"
          transform="translate(0 0.167969)"
        />
      </clipPath>
    </defs>
  </svg>
);