import React from 'react'
import Logo from '../../assets/logo.png'
import SaudFlag from "../../assets/artwork.png";
import  './header.css'

const Header = () => {
  return (
    <div className="header">
      <header className="header__content container">
        {/* logo */}
        <img src={Logo} alt="" className="header__logo" />
        {/* title */}
        <h2 className="header__title">Price Intelligence Solution</h2>
        <nav className="header__nav">
          <div>DEMO</div>
          <span className="dividing"></span>
          <div>Help</div>
          <span className="dividing"></span>
          <div className="language__btn">
            <img className="saud__flag" src={SaudFlag} alt="" /> عربي
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header