import React, { useState } from "react";
import Header from "./components/Header";
import { FilterIcon, SearchIcon } from "./icons";
import Statics from "./components/Statics";
import Table from "./components/Table";
import { ImMenu } from "react-icons/im";
import { BsFillGridFill } from "react-icons/bs";
import CardsList from "./components/CardsList";
// import Spinner from "./components/Spinner";
import MainSelectBox from "./components/MainSelectBox";
import { useApi } from "./hooks/useApi";


  const options = [
    { id: "all", label: "All Platforms" },
    { id: "amazon", label: "Amazon" },
    { id: "jarir", label: "Jarir" },
    { id: "office", label: "office" },
  ];

const App = () => {
  const [text, setText] = useState("notebook");
  const [TableView, setTableView] = useState(false);
  const [active, setActive] = useState(options[2]);

  const { data, loading, onRequest } = useApi("/scrape", "post");

  return (
    <>
      <Header />
      <div className="container" style={{ paddingBottom: "20px" }}>
        <h2 className="title">Search product here to find the best price</h2>
        <p className="subtitle">
          all what you want to do is serch with product name then let our magic
          work
        </p>
        <div className="input__box">
          <SearchIcon />
          <input
            onChange={(e) => setText(e?.target?.value)}
            type="text"
            placeholder="Search Products here"
            className="input"
            value={text || ""}
          />

          <button
            type="button"
            onClick={() => onRequest({ platform: active?.id, text })}
            className="search__btn"
          >
            {loading ? "Loading...!" : "Search"}
          </button>
        </div>
        {!data && !loading ? (
          <h3
            style={{
              padding: "20px",
              border: "1px solid #DDD",
              height: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Please Search To Show Results
          </h3>
        ) : (
          <>
            <Statics
              loading={loading}
              statics={{
                averagePrice: data?.averagePrice,
                lowestPrice: data?.lowestPrice,
                highestPrice: data?.highestPrice,
              }}
            />
            <section>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "18px",
                }}
              >
                <h3 className="section__title" style={{ marginBottom: 0 }}>
                  Search results <span>{data?.products?.length} product</span>
                </h3>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <div className={"toggle__controller__btn"}>
                    <button
                      className={`${TableView ? "" : "active"}`}
                      onClick={() => setTableView(false)}
                    >
                      <BsFillGridFill />
                    </button>
                    <button
                      className={`${TableView ? "active" : ""}`}
                      onClick={() => setTableView(true)}
                    >
                      <ImMenu />
                    </button>
                  </div>
                  <MainSelectBox
                    options={options}
                    onActive={(e) => {
                      setActive(e);
                      onRequest({ platform: e?.id, text });
                    }}
                    active={active}
                    defaultText={"See all suppliers"}
                    id={"filter"}
                    color="blue"
                    icon={<FilterIcon />}
                  />
                </div>
              </div>
              {!data?.products?.length && !loading ? (
                <div className={"empty__list"}>No Results!</div>
              ) : TableView ? (
                <Table loading={loading} data={data?.products} />
              ) : (
                <CardsList data={data?.products} loading={loading} />
              )}
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default App;
